<template>
  <div style="text-align: center;width: 100%;min-height: 250px">
    <img :src="warnImage" alt="" style="margin-top: 230px;width: 400px;height: 250px;margin: 230px auto auto auto">
    <div style="font-size: 15px;
font-family: PingFang SC;
font-weight: 500;
line-height: 21px;
color: #797A8E;">维护中</div>
  </div>
</template>

<script>
  /**
   * 维护缺省页
   */
  export default {
    name: "maintain-page",
    data() {
      return {
        warnImage: require("@assets/img_maintain.png"),

      }
    }
  }
</script>

<style scoped>

</style>
